/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewUser.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordField from "../../components/PasswordField/PasswordField";
import { useForm } from "react-hook-form";

function ResetPasswordModal({ show, handleClose, userId }) {
  const [submiting, setSubmitting] = useState(false);
  const [perror, setPerror] = useState(null);
  const { errormsgStyle, setSuspend } = useContext(TableContext);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmitdata(data) {
    console.log("datat", data);
    // if (!data.password.match(/\d/) || !data.password.match(/[a-zA-Z]/)) {
    //   setError('Password must contain at least 1 letter and 1 number');
    //   return
    // }
    setSubmitting(true);
    const apiData = {
      password: data.password,
      userId: userId,
    };
    data.actionUrl = "user/reset-password";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      console.log("responsee-----", resp);
      setSubmitting(false);
      if (resp.payload.code === 200) {
        handleClose();
        toast.success("Password changed successfully");
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError("Something went wrong!");
      }
    });
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Your reset password form can go here */}
        {/* Example: */}
        <form onSubmit={handleSubmit(onSubmitdata)}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <PasswordField
                fieldName="password"
                className={styles.inputbox}
                classNamedark={styles.inputbox1}
                labelstyle={styles.formlabel}
                label="Password"
                register={register}
                errors={errors}
                placeHolder="Enter Password"
                isRequired
                minimLength={8}
                eyeiconstyle={styles.eyeicon}
                mandatory
              />
              {/* {perror && <p className={styles[errormsgStyle]}>{perror}</p>} */}
            </div>
          </div>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          {error && (
            <h6 className={styles[errormsgStyle]} style={{ float: "right" }}>
              {error}
            </h6>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}

function ViewUsers() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const dispatch = useDispatch();
  const params = useParams();
  let userId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [roles, setRole] = useState([]);
  const dateFormat = getCookies("dateFormat");
  const [canDelete, setCanDelete] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleResetPassword = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/${userId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          console.log("res.data.user", res.data.user);
          setDetails(res.data.user);
          setRole(res.data.user.role);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    userId = params.userId;
    getUserDetails();
    setDashboardHeader("User Details");
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure, you want to delete",
      buttons: canDelete
        ? [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `vendor/${id}`;
                actionData.actionMethod = "delete";
                dispatch(Entry(actionData)).then(() => {
                  navigate("/vendor");
                  toast.success("Vendor deleted successfully");
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
        : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
    });
  };
  return (
    <>
      <div className={styles[bodyStyle]}>
        <div
          className={styles[dashboardStyle]}
          style={{ height: "100vh" }}
          id={styles.dashboardcont2}
        >
          <div className={styles[formthemeStyle]} id={styles.addform}>
            {loading ? (
              <SpinnerDotted
                style={{
                  color: "#39979d",
                  left: "50%",
                  position: "relative",
                  textAlign: "center",
                  top: "50%",
                }}
              />
            ) : (
              <Container fluid>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  value="Back"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className={styles.arrowback}
                />
                <Row>
                  <Col lg={12}>
                    <div className={styles.profilepicdiv}>
                      {details.image ? (
                        <Image
                          src={details.image}
                          alt="logo"
                          className={styles.profilepic}
                        />
                      ) : (
                        <div>{details.name}</div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12}>
                    <div className={styles.detaildiv}>
                      <Row>
                        <Col lg={4}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>Name</span>
                            <p className={styles.empname}>{details.name}</p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>Email</span>
                            <p className={styles.empname}>{details.email}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>Phone Number</span>
                            <p className={styles.empname}>
                              {details?.phone ?? "NA"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>User Type</span>
                            <p className={styles.empname}>
                              {details?.userType}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>Location</span>
                            <p className={styles.empname}>
                              {details?.location_name ?? "NA"}
                            </p>

                            {/* <p className={styles.empname}>
                            {details.dateOfBirth
                              ? DateFormatter(
                                  details.dateOfBirth,
                                  dateFormat || "toDateString"
                                )
                              : "NA"}
                          </p> */}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>
                              Total No.of Orders
                            </span>
                            <p className={styles.empname}>
                              {details.totalOrders ?? 0}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div
                            // className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <Button
                              className={`${styles.title} btn-danger`}
                              onClick={() => {
                                handleDelete(details?._id);
                              }}
                            >
                              Delete User
                            </Button>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            // className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <Button
                              className={styles.title}
                              onClick={() => setShowModal(true)}
                            >
                              Reset Password
                            </Button>
                            <ResetPasswordModal
                              show={showModal}
                              handleClose={handleCloseModal}
                              userId={details?._id}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewUsers;
