/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Image, Container, Button, Modal} from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import styles from "./viewPopup.module.scss";
import TextInput from "../../components/TextField/TextField";
import SelectBox from "../../components/SelectBox/SelectBox";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable/CustomTable";
import { REACT_APP_API_FETCH_MENUS } from "../../redux/apiConstants";

function SuspendCafeModal({ show, handleClose, cafeId }) {
    const [submiting, setSubmitting] = useState(false);
  
    const periods = [
      {
        value: "1week",
        label: "1 Week",
      },
      {
        value: "1month",
        label: "1 Month",
      },
      {
        value: "6month",
        label: "6 Month",
      },
    ];
  
    console.log("cafe Id ", cafeId);
  
    const { errormsgStyle, setSuspend } = useContext(TableContext);
    const [error, setError] = useState(null);
  
    const dispatch = useDispatch();
  
    const {
      control,
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
  
    function onSubmitdata(data) {
      console.log("datat", data);
  
      setSubmitting(true);
      const apiData = {
        suspendReason: data.reason,
        suspendPeriod: data.period,
      };
      data.actionUrl = `cafe/suspend-cafe/${cafeId}`;
      data.actionMethod = "post";
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        console.log("responsee-----", resp);
        setSubmitting(false);
        if (resp.payload.code === 200) {
          handleClose();
          toast.success("Cafe suspended successfully");
          setSuspend(true);
        } else if (resp.payload.code === 401 || resp.payload.code === 400) {
          setError(resp.payload.message);
          toast.success("Cafe suspended successfully");
        } else {
          setError(resp.payload.message);
        }
      });
    }
  
    const handleSuspend = () => {
      // Validate reason and period if needed
      // suspendCafe(reason, period);
      handleClose();
    };
  
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suspend Cafe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitdata)}>
            <div>
              <TextInput
                className={styles.inputbox}
                classNamedark={styles.inputbox1}
                labelstyle={styles.formlabel}
                label="Reason"
                fieldName="reason"
                placeHolder="Enter reason"
                register={register}
                errors={errors}
                isRequired
                mandatory
              />
            </div>
            <div>
              <SelectBox
                className={styles.inputbox}
                classNamedark={styles.inputbox1}
                name="period"
                label="Period"
                labelstyle={styles.formlabel}
                control={control}
                register={register}
                values={periods}
                errors={errors}
                placeholder="Select Period"
                mandatory
                isRequired
              />
            </div>
            <Button color="primary" type="submit">
              Suspend
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {error && (
              <h6 className={styles[errormsgStyle]} style={{ float: "right" }}>
                {error}
              </h6>
            )}
          </form>
        </Modal.Body>
      </Modal>
    );
  }

const ViewCafePopup = React.memo(({ cafeId, handleClose }) => {
    const { dashboardStyle } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { bodyStyle } = useContext(TableContext);
    const { formthemeStyle, bodyheader, viewformStyle } = useContext(TableContext);
    const { suspend, setSuspend } = useContext(TableContext);
    const dispatch = useDispatch();
    const params = useParams();
    // let payoutId;
    const token = `Bearer ${getCookies("Token")}`;
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const dateFormat = getCookies("dateFormat");
    const [canDelete, setCanDelete] = useState(true);
    const [cafeList, setCafesList] = useState([]);
    const [showModal, setShowModal] = useState(false);
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    let statusCode;
    const getUserDetails = async () => {
      setLoading(true);
      axios
        .get(`${envValues.REACT_APP_API_ENDPOINT}/cafe/${cafeId}`, {
          method: "GET",
          headers: { Authorization: token },
        })
        .then((resp) => {
          statusCode = resp.status;
          return resp.data;
        })
        .then((res) => {
          if (statusCode === 200) {
            console.log("res.data.user", res.data.cafe);
            setDetails(res.data.cafe);
            setSuspend(res.data.cafe?.isSuspended);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          return err;
        });
    };
    const navigate = useNavigate();
    useEffect(() => {
      window.scrollTo(0, 0);
    //   cafeId = params.cafeId;
      getUserDetails();
      setDashboardHeader("Cafe Details");
    }, []);
  
    const handleDelete = (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure, you want to delete",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/${id}`;
                  actionData.actionMethod = "delete";
                  dispatch(Entry(actionData)).then(() => {
                    navigate(-1);
                    toast.success("Cafe deleted successfully");
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };
  
    const removeSuspension = (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure, you want to remove suspension",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/un-suspend-cafe/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData)).then(() => {
                    // navigate(-1);
                    setSuspend(false);
                    toast.success("Cafe unsuspended successfully");
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };
  
    const goToMenu = async () => {
      console.log("params.cafeId", params.cafeId);
      navigate(`/menu/${params.cafeId}`);
    };
  
    const handleReject = async (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure, you want to reject the cafe request.",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/reject-cafe/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData)).then(() => {
                    navigate(-1);
                    setSuspend(false);
                    toast.success("Cafe request rejected successfully");
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };
  
    const handleApprove = async (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure, you want to approve the cafe request.",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/approve-cafe/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData)).then(() => {
                    navigate(-1);
                    setSuspend(false);
                    toast.success("Cafe request approved successfully");
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        {loading ? (
          <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
              ) :(
                <Container fluid>
                  {/* <FontAwesomeIcon
                    icon={faArrowLeft}
                    value="Back"
                    onClick={() => {
                      navigate(-1);
                    }}
                    className={styles.arrowback}
                  /> */}
                          <Row>
                          <h5 className={styles[bodyheader]} id={styles.addheading}>
                Cafe Details
              </h5>
                    <Col lg={8}>
                      <div className={styles.profilepicdiv}>
                        {details.image ? (
                          <Image
                            src={details.image}
                            alt="logo"
                            className={styles.profilepic}
                          />
                    ) :null
                      // (
                      //     <div>{details.name}</div>
                      //   )
                      }
                      </div>
                    </Col>
                    {/* <Col lg={4}>
                      <div className={styles.profilepicdiv}>
                        <Button
                          onClick={() => {
                            goToMenu();
                          }}
                        >
                          Go to menu page
                        </Button>
                      </div>
                    </Col> */}
                  </Row>
                  <Row className="mt-3">
                    <Col lg={12} md={12} sm={12}>
                      <div className={styles.detaildiv}>
                        <Row>
                          <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>Name</span>
                              <p className={styles.empname}>{details.name}</p>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>
                                Location (For GPS)
                              </span>
                              <p className={styles.empname}>
                                {details.location_name ?? "NA"}
                              </p>
                            </div>
                      </Col>
                      <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>Address</span>
                              <p className={styles.empname}>
                                {details?.address ?? "NA"}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>Working Hours</span>
                              {details?.timings && details.timings.length > 0 ? (
                                details.timings.map((timing, index) => (
                                  <p key={index} className={styles.empname}>
                                    {/* {timing.startTime}-{timing.endTime} */}
                                  </p>
                                ))
                              ) : (
                                <p className={styles.empname}>NA</p>
                              )}
                            </div>
                      </Col>
                      <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>Name of Vendor</span>
                              <p className={styles.empname}>
                                {details?.vendor?.name ?? "NA"}
                              </p>
                            </div>
                      </Col>
                      
                      <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>Email</span>
                              <p className={styles.empname}>
                                {details?.vendor?.email ?? "NA"}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>
                                Total Orders Completed
                              </span>
                              <p className={styles.empname}>
                                {details.totalOrders ?? 0}
                              </p>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>
                                Social Media Links
                              </span>
                              <p className={styles.empname}>
                                {details.facebook ? (
                                  <a
                                    href={details.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Facebook
                                  </a>
                                ) : null}
                                {details.facebook && details.instagram
                                  ? " / "
                                  : null}
                                {details.instagram ? (
                                  <a
                                    href={details.instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Instagram
                                  </a>
                                ) : null}
                                {!details.facebook && !details.instagram
                                  ? "NA"
                                  : null}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row></Row> */}
                        <Row>
                          <Col lg={2}>
                            <div
                              // className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <Button
                                className={`${styles.title} btn-success`}
                                onClick={() => {
                                  handleApprove(details?._id);
                                }}
                              >
                                Approve Cafe
                              </Button>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div
                              // className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <Button
                                className={`${styles.title} btn-danger`}
                                onClick={() => {
                                  handleReject(details?._id);
                                }}
                              >
                                Reject Cafe
                              </Button>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div
                              // className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              {suspend ? (
                                <Button
                                  className={styles.title}
                                  onClick={() => removeSuspension(details?._id)}
                                >
                                  Remove Suspension
                                </Button>
                              ) : (
                                <Button
                                  className={`${styles.title} btn-secondary`}
                                  onClick={() => setShowModal(true)}
                                >
                                  Suspend Cafe
                                </Button>
                              )}
    
                              <SuspendCafeModal
                                show={showModal}
                                handleClose={handleCloseModal}
                                cafeId={details?._id}
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div
                              // className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <Button
                                className={`${styles.title} btn-danger`}
                                onClick={() => {
                                  handleDelete(details?._id);
                                }}
                              >
                                Delete Cafe
                              </Button>
                            </div>
                      </Col>
                      {details?.cafeStatus === "Pending" && <Row></Row>}
                        {!details?.isClaimed && !details.isClaimed && (
                          // <Row>
                            <Col lg={4}>
                              <div
                                // className={styles[viewformStyle]}
                                id={styles.empdiv}
                              >
                            <Button
                                   className={`${styles.title} btn-primary`}
                                  disabled={details?.claimRequests?.length === 0}
                                  onClick={() =>
                                    navigate(`/claim-requests/${params.cafeId}`)
                                  }
                                >
                                  Claim Requests:{" "}
                                  {details?.claimRequests?.length || 0}
                                </Button>
                              </div>
                            </Col>
                          // </Row>
                        )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  {cafeList.length > 0 && (
                    <Row>
                      <Col lg={12}>
                        <h5>Cafes</h5>
                        <ol>
                          {cafeList?.map((cafe, index) => (
                            <li key={cafe.id}>
                              {/* <span>{index + 1}. </span> */}
                              <span>{cafe.name}</span>
                              <span> {cafe?.location_name}</span>
                            </li>
                          ))}
                        </ol>
                      </Col>
                </Row>
              )}
            </Container>
        )}
              <Row>
        <Col lg={12}>
          <CustomTable
            urlParam={`${REACT_APP_API_FETCH_MENUS}/${cafeId}?`}
            viewPopUp = {true}
            section="Menus"
            toggleButton={{ show: true, field: "active" }}
            deleteCondition={{
              checkCondition: false,
              apiUrl: "www.google.com",
            }}
          />
        </Col>
      </Row>
      </div>
    </div>
  );
});

export default ViewCafePopup;
