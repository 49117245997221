/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import TableViewPopup from "../TableViewPopup/TableViewPopup";
import ViewVendorPopup from "../../pages/vendors/viewVendorPopup";
import ViewFeedbackPopup from "../../pages/feedback/viewFeedbackPopup";
import ViewPayoutPopup from "../../pages/payoutRequests/viewPayoutReqPopup";
import ViewDrinkTypePopup from "../../pages/drinktypes/viewDrinkTypePopup";
import ViewEnquiryPopup from "../../pages/contactus/viewContactusPopup";
import ViewCafePopup from "../../pages/cafes/viewCafePopup";
import styles from "./ActionButtons.module.scss";
import useToggle from "../../hooks/useToggle";
import { Entry } from "../../redux/entrySlice";
import { TableContext } from "../../contexts/tableContext";
import { getCookies } from "../../hooks/useCookies";
import EditUserPopup from "../../pages/users/editUserPopUp";
import EditVendorPopup from "../../pages/vendors/editUserPopUp";
import EditCafePopup from "../../pages/cafes/editCafePopUp";
import EditDrinkTypePopup from "../../pages/drinktypes/editDrinktypePopup";

function ActionButtons({
  data,
  section,
  viewPopUp,
  deleteCondition,
  setTableData,
}) {
  const { actionbtnStyle } = useContext(TableContext);
  const [actionPermissions, setActionPermission] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setDeletemessage] = useState();
  const [canDelete, setCanDelete] = useState(true);
  const { setDeletedData } = useContext(TableContext);
  const [showTablePopUp, setShowTablePopUp] = useToggle();

  // Track whether "view" or "edit" is selected
  const [action, setAction] = useState("view");

  // Function for "view" action
  const actionView = (param) => () => {
    setAction("view");  // Set action to "view"
    const encodeText = param.id;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      navigate(`/${section}/viewdetails/${encodeText}`);
    }
  };

  // Function for "edit" action
  const actionEdit = (param) => {
    setAction("edit");  // Set action to "edit"
    const encodeText = param.id;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      navigate(`/${section}/editdetails/${encodeText}`);
    }
  };

  // Handle delete action
  const handleDelete = (params) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: `Are you sure, you want to delete ${data.name} ?`,
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `${section}/${params.id}`;
              actionData.actionMethod = "delete";
              dispatch(Entry(actionData)).then((resp) => {
                if (resp.payload.code === 400) {
                  confirmAlert({
                    title: "Oops! Cant Delete!",
                    message: resp.payload.message,
                    buttons: [{ label: "Ok" }],
                  });
                } else {
                  toast.success("Row deleted successfully");
                  setDeletedData(`data last deleted is ${params.id}`);
                  setTableData((prevData) =>
                    prevData.filter((item) => item.id !== params.id)
                  );
                }
              });
            },
          },
          {
            label: "No",
          },
        ]
        : [{ label: "Ok" }],
    });
  };

  // Check user permissions and delete condition
  useEffect(() => {
    const temp = section.replace(/\s+/g, "").toLowerCase();
    const permissions = getCookies("USERPERMISSION");
    permissions.forEach((val) => {
      if (val.section.toLowerCase() === temp) {
        setActionPermission({
          view: val.view ? val.view : false,
          edit: val.edit ? val.edit : false,
          create: val.create ? val.create : false,
          delete: val.delete ? val.delete : false,
        });
      }
    });
    if (deleteCondition.checkCondition) {
      setCanDelete(false);
      setDeletemessage("Sorry you cant delete this data");
    }
  }, []);

  return (
    <>
      <div className="col-lg-4 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
        {actionPermissions.view && (
          <button
            className={`${styles[actionbtnStyle]} btn btn-sm`}
            id={styles.actionbtn}
            type="button"
            onClick={actionView(data)}
          >
            {" "}
            <i className="fa fa-eye" />
          </button>
        )}
        {actionPermissions.edit && section !== "payoutaccounts" && (
          <button
            className={`${styles[actionbtnStyle]} btn btn-sm`}
            id={styles.actionbtn}
            type="button"
            onClick={() => {
              actionEdit(data);
            }}
          >
            {" "}
            <i className="fa fa-pen" />
          </button>
        )}
        {actionPermissions.delete && (
          <button
            className={`${styles[actionbtnStyle]} btn btn-danger btn-sm`}
            id={styles.actionbtn1}
            type="button"
            onClick={() => {
              handleDelete(data);
            }}
          >
            {" "}
            <i className="fa fa-trash" />
          </button>
        )}
      </div>

      {/* Conditional Popup based on action and section */}
      {showTablePopUp && (
  section === "user" ? (
    action === "edit" ? (
      <EditUserPopup
        userId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : (
      <TableViewPopup
        dataId={data.id}
        handleClose={setShowTablePopUp}
        section={section}
      />
    )
  ) : section === "vendor" ? (
    action === "edit" ? (
      <EditVendorPopup
        vendorId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : (
      <ViewVendorPopup
        vendorId={data.id}
        handleClose={setShowTablePopUp}
      />
    )
  ) : section === "cafe" ? (
    action === "edit" ? (
      <EditCafePopup
        cafeId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : (
      <ViewCafePopup
        cafeId={data.id}
        handleClose={setShowTablePopUp}
      />
    )
  ) : section === "drinktypes" ? (
    action === "edit" ? (
      <EditDrinkTypePopup
        drinkTypeId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : (
      <ViewDrinkTypePopup
        drinkTypeId={data.id}
        handleClose={setShowTablePopUp}
      />
    )
  ) : (
    // For all other sections, only show view popups
    section === "feedback" ? (
      <ViewFeedbackPopup
        feedbackId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : section === "payoutrequests" ? (
      <ViewPayoutPopup
        payoutId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : section === "enquiries" ? (
      <ViewEnquiryPopup
        contactUsId={data.id}
        handleClose={setShowTablePopUp}
      />
    ) : (
      <TableViewPopup
        dataId={data.id}
        handleClose={setShowTablePopUp}
        section={section}
      />
    )
  )
)}



    </>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  viewPopUp: PropTypes.bool.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
};

ActionButtons.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: "none" },
};

export default ActionButtons;
