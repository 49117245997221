/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Adjusted to import from free solid icons
import { Entry } from "../../redux/entrySlice";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import styles from "./editUserPopUp.module.scss";
import { TableContext } from "../../contexts/tableContext";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";

const EditUserPopup = React.memo(({ userId, handleClose }) => {
  const [submiting, setSubmitting] = useState(false);
  const { formthemeStyle, bodyheader, viewformStyle } = useContext(TableContext);
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const url2 = REACT_APP_API_ROLES_DROPDOWN; // api url
    fetch(url2)
      .then((resp) => resp.json())
      .then((resp) => {
        setRoles(resp.data.role);
      });

    const actionData = { actionUrl: `user/${userId}`, actionMethod: "get" };
    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.user) {
        const userData = resp.payload.data.user;
        setUser(userData);
        reset(userData); // Reset the form with user data
      } else {
        console.error("User data not found in response:", resp);
        setUser(null);
      }
    });
  }, [userId]);

  const onSubmitdata = (data) => {
    if (data.name.trim().length === 0) {
      document.getElementById("name").focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        File: files,
      };
      data.actionUrl = `user/${userId}`;
      data.actionMethod = "patch";
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success("User updated successfully");
          handleClose(); // Close the popup after successful update
        } else {
          setError(resp.payload.message);
          toast.error("Error updating user");
        }
      });
    }
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box} id={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Edit User Details</h2>
        <div className="container-fluid mt-5 edituserform">
          {user ? (
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    maximLength="50"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <EmailInput
                    className={styles.inputbox}
                    label="Email"
                    fieldName="email"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    placeHolder="Email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <PhoneNumberInput
                    label="Phone Number"
                    placeHolder="Phone Number"
                    className={styles.inputbox}
                    fieldName="phone"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-sm-6">
                  <MultipleFileUpload editFiles={user.image} section="user" />
                </div>
              </div>
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting}
                value={submiting ? "Please wait.." : "Update"}
              />
              {error && <h6 className={styles.errormsg}>{error}</h6>}
            </form>
          ) : (
            <div style={{ textAlign: "center", marginTop: "130px", color: "#39979d" }}>
              <SpinnerDotted />
              <p>No user data found</p>
            </div>
          )}
          <ToastContainer autoClose={2000} />
        </div>
      </div>
    </div>
  );
});

export default EditUserPopup;
