/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import LocationInput from "../../components/LocationSelectBox/LocationSelectBox"

import styles from "./editCafePopUp.module.scss"; // Popup specific styles
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import { REACT_APP_API_CAFE } from "../../redux/apiConstants";

const EditCafePopup = React.memo(({ cafeId, handleClose }) => {
  const [submitting, setSubmitting] = useState(false);
  const [cafe, setCafe] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const actionData = { actionUrl: `cafe/${cafeId}`, actionMethod: "get" };
    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.cafe) {
        setCafe(resp.payload.data.cafe);
        console.log(resp.payload)
        reset(resp.payload.data.cafe); // Reset the form with cafe data
      } else {
        console.error("Cafe data not found in response:", resp);
        setCafe(null);
      }
    });
  }, [cafeId, dispatch, reset]);

  const onSubmitData = (data) => {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      File: data.files,
    };
    data.actionUrl = `cafe/${cafeId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Cafe updated successfully");
        handleClose(); // Close the popup after successful update
      } else {
        setError(resp.payload.message);
        toast.error("Error updating cafe");
      }
    });
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Edit Cafe Details</h2>
        <div className="container-fluid mt-5 editcafeform">
          {cafe ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Cafe Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Address"
                    fieldName="address"
                    placeHolder="Enter Cafe Address"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  {/* <EmailInput
                    className={styles.inputbox}
                    label="Email"
                    fieldName="email"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    placeHolder="Email"
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                {/* <LocationInput/> */}

                  {/* <PhoneNumberInput
                    label="Phone Number"
                    placeHolder="Phone Number"
                    className={styles.inputbox}
                    fieldName="phone"
                    register={register}
                    errors={errors}
                  /> */}
                </div>
                
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-sm-6">
                  <MultipleFileUpload section="cafe" />
                </div>
              </div>
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submitting}
                value={submitting ? "Please wait.." : "Update"}
              />
              {error && <h6 className={styles.errormsg}>{error}</h6>}
            </form>
          ) : (
            <div style={{ textAlign: "center", marginTop: "130px", color: "#39979d" }}>
              <SpinnerDotted />
              <p>No cafe data found</p>
            </div>
          )}
          <ToastContainer autoClose={2000} />
        </div>
      </div>
    </div>
  );
});

export default EditCafePopup;
