/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import TextInput from "../../components/TextField/TextField";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import styles from "./editDrinktypePopup.module.scss"; // Popup specific styles
import "react-toastify/dist/ReactToastify.css";
import { TableContext } from "../../contexts/tableContext";

const EditDrinkTypePopup = React.memo(({drinkTypeId,handleClose }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const { control, register, handleSubmit, formState: { errors }, reset } = useForm();
  
  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // const drinktypeId = params.drinktypeId;

    const actionData = {
      actionUrl: `drinktypes/${drinkTypeId}`,
      actionMethod: "get",
    };

    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload.data.drinkType) {
        setUser(resp.payload.data.drinkType);
        reset(resp.payload.data.drinkType); // Reset the form with drink type data
      }
    });
  }, [drinkTypeId, dispatch, reset]);

  const onSubmitData = (data) => {
    setSubmitting(true);
    setError(null);
    const apiData = {
      type: data.type,
      File: files,
    };
    data.actionUrl = `drinktypes/${drinkTypeId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;

    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Drink type updated successfully");
        handleClose(); // Close the popup after successful update
      } else {
        setError(resp.payload.message);
        toast.error("Error updating drink type");
      }
    });
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Edit Drink Type</h2>
        <div className="container-fluid mt-5 editdrinktypeform">
          {user ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Drink Type"
                    fieldName="type"
                    placeHolder="Enter Drink Type"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    maxLength="50"
                    defaultValue={user.type}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-sm-6">
                  <MultipleFileUpload editFiles={user?.image} section="drinktype" />
                </div>
              </div>
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submitting}
                value={submitting ? "Please wait.." : "Update"}
              />
              {error && <h6 className={styles.errormsg}>{error}</h6>}
            </form>
          ) : (
            <div style={{ textAlign: "center", marginTop: "130px", color: "#39979d" }}>
              <SpinnerDotted />
            </div>
          )}
          <ToastContainer autoClose={2000} />
        </div>
      </div>
    </div>
  );
});

export default EditDrinkTypePopup;
